import { UtilityProgram } from '@/types/utilityProgram'
import { useMockableViewModel } from '@/hooks'
import { useProgramRequirements } from '@/app/hooks/useProgramRequirements'
import {
  ClientRequirements,
  ProgramRequirementProps,
} from '@/types/utilityProgramProfileEligibility'

export type ProgramRequirementSheetProps = {
  utilityProgram: UtilityProgram
}

export const viewModel = useMockableViewModel({
  useViewModel,
  useMockViewModel,
})

function useViewModel({ utilityProgram }: ProgramRequirementSheetProps) {
  const {
    routeToNextStep,
    saveForLater,
    completeEnrollment,
    programRequirements,
    routeToStep,
    routeToNextUncompletedStep,
  } = useProgramRequirements(utilityProgram)
  return {
    routeToNextStep,
    saveForLater,
    completeEnrollment,
    programRequirements,
    routeToStep,
    routeToNextUncompletedStep,
  }
}

function useMockViewModel() {
  return {
    routeToNextStep: () => {},
    saveForLater: () => {},
    routeToStep: () => {},
    completeEnrollment: () => {},
    programRequirements: {
      planName: 'Test Plan',
      step: 1,
      numberOfSteps: 1,
      steps: [
        {
          name: 'Select devices to enroll',
          completed: true,
          isNew: false,
          path: '',
          key: ClientRequirements.SelectDevice,
        },
      ],
    } as ProgramRequirementProps,
    routeToNextUncompletedStep: () => {},
  }
}

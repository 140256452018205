import formatClasses from '@/utils/classes/formatClasses'
import { Bar } from '@/components'
import Text from '@/components/Text'

type ProgressBarProps = {
  step: number
  numberOfSteps: number
  textType?: 'numbers' | 'percent' | 'hidden'
}
export function ProgressBar(props: ProgressBarProps) {
  const classes = {
    progressBar: formatClasses(['progress-bar']),
    progress: formatClasses(['progress']),
  }

  return (
    <div className={'flex flex-row gap-6 items-center'}>
      <Text
        style={{
          fontVariantNumeric: 'tabular-nums',
        }}
        className={'min-w-fit text-themed-blue-500'}
      >
        {props.textType === 'numbers' ||
          (!props.textType &&
            `${props.step} of ${props.numberOfSteps} complete`)}
        {props.textType === 'percent' &&
          `${Math.floor((props.step / props.numberOfSteps) * 100)}% Complete`}
      </Text>
      <Bar className={classes.progressBar}>
        <Bar
          className={classes.progress}
          style={{
            background: 'var(--color-blue-500)',
            width: `clamp(2%, calc(100% * ${props.step} / ${props.numberOfSteps}), 100%)`,
          }}
        />
      </Bar>
    </div>
  )
}

import { UtilityProgram } from '@/types/utilityProgram'
import { ProfileEligibilityRequirements } from '../programs-new/profile-requirements'

export type UtilityPlanProfileEligibility = {
  id: number
  program: UtilityProgram
  devices: DeviceRequirements
  profile_result: ProfileResult
}
type ProfileResult = {
  name: string
  is_eligible: boolean
  requirements_result: RequirementResult[]
}
type RequirementResult = {
  key: string
  name: string
  reason: string
  is_eligible: boolean
  ineligible_reasons: string[]
  optional: boolean
}

type IneligibleReason = {
  name: string
  is_eligible: boolean
  requirements_result: RequirementResult[]
  id: number | null
}

export type DeviceCategory = {
  ineligible_reasons: IneligibleReason[]
  eligible_ids: number[]
  has_connected: boolean
}

export type DeviceRequirements = {
  chargers?: DeviceCategory
  vehicles?: DeviceCategory
  thermostats?: DeviceCategory
  behavioral?: DeviceCategory
}
export default UtilityPlanProfileEligibility
export type RequirementStepConfig = {
  name: string
  path: string
  clientStep?: boolean
}

export enum ClientRequirements {
  SelectDevice = 'SelectDevice',
  ProgramEnrollment = 'ProgramEnrollment',
  ReviewTOS = 'ReviewTOS',
}

export type ProgramRequirementStep = {
  completed: boolean
  isNew: boolean
  key: ProfileEligibilityRequirements | ClientRequirements
} & RequirementStepConfig

export type ProgramRequirementProps = {
  step: number
  numberOfSteps: number
  planName: string
  steps: ProgramRequirementStep[]
}

// map of supported profile/client steps & their configs
export const requirementNameConfig = new Map<
  ProfileEligibilityRequirements | ClientRequirements,
  RequirementStepConfig
>([
  [
    ProfileEligibilityRequirements.RequireUtility,
    { name: 'Select an eligible utility', path: 'utility-selection' },
  ],
  [
    ProfileEligibilityRequirements.Device,
    { name: 'Select devices to enroll', path: 'device-selection' },
  ],
  [
    ClientRequirements.ProgramEnrollment,
    {
      name: 'Enroll in program',
      path: 'review-enrollment',
    },
  ],
  [
    ClientRequirements.ReviewTOS,
    {
      name: 'Review Terms of Service',
      path: 'review-tos',
    },
  ],
])

import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import {
  InProgressUtilityProgramEnrollment,
  selectInProgressEnrollment,
} from '@/reducers/inProgressUtilityProgramEnrollments'

import { UtilityProgram } from '@/types/utilityProgram'
import {
  ClientRequirements,
  ProgramRequirementProps,
  ProgramRequirementStep,
  requirementNameConfig,
  RequirementStepConfig,
} from '@/types/utilityProgramProfileEligibility'
import { useNavigation } from '@/app/hooks/useNavigation'
import { useBottomSheet } from '@/context/bottom-sheet-context'
import { programsNewCollection } from '@/reducers/programsNew'
import { ProfileEligibilityRequirements } from '@/types/programs-new/profile-requirements'
import { useAppSelector } from '@/request/utils'
import { useAppDispatch } from '@/hooks'

export const useProgramRequirements = (utilityProgram: UtilityProgram) => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const navigate = useNavigation()
  const { closeBottomSheet } = useBottomSheet()

  const { data, isLoading } = programsNewCollection.useFetch()
  const program = useAppSelector(selectInProgressEnrollment(utilityProgram.id))

  const [programRequirements, setProgramRequirements] =
    useState<ProgramRequirementProps | null>(null)
  const [prevProgramRequirements, setPrevProgramRequirements] =
    useState<ProgramRequirementProps | null>(null)

  useEffect(() => {
    if (!isLoading) {
      return
    }
    dispatch(programsNewCollection.actions.invalidate())
  }, [utilityProgram.id, location.pathname])

  // effect for updating plan requirements based on state changes from server & local client
  useEffect(() => {
    const requirements = data.find(
      (d) => d.utility_program.id === utilityProgram.id,
    )?.device_eligibility_overview.profile.requirements_result

    if (!requirements) {
      return
    }

    const serverSteps = requirements
      .filter((req) => requirementNameConfig.get(req.key))
      .map((req) => {
        const config = requirementNameConfig.get(
          req.key,
        ) as RequirementStepConfig
        return {
          name: config.name,
          path: `/multi-program-enrollment/${utilityProgram.id}/${config.path}`,
          key: req.key as ProfileEligibilityRequirements,
          completed: req.is_eligible,
          // field for later
          isNew: false,
        }
      })

    const steps = addClientSteps(serverSteps, utilityProgram.id, program)

    setPrevProgramRequirements(programRequirements)
    setProgramRequirements({
      step: steps.filter((step) => step.completed).length,
      numberOfSteps: steps.length,
      steps,
      planName: utilityProgram.name,
    })
  }, [data, program])

  // effect for manually routing through steps based on step progress
  useEffect(() => {
    if (!location.pathname.startsWith('/multi-program-enrollment')) {
      return
    }
    if (!programRequirements) {
      return
    }
    // skip routing if we are just in a transient loading state
    if (programRequirements && !prevProgramRequirements) {
      return
    }
    if (location.pathname.includes('enrollment-state')) {
      return
    }
    const nextStep = programRequirements.steps.filter(
      (step) => !step.completed,
    )?.[0]
    if (!nextStep) {
      return
    }
    if (location.pathname !== nextStep.path) {
      navigate.push(nextStep.path)
    }
  }, [programRequirements?.step])

  const completeEnrollment = () => {
    closeBottomSheet()
    navigate.push(`/devices`)
  }
  const saveForLater = () => {
    closeBottomSheet()
    if (!location.pathname.includes('/devices')) {
      navigate.push('/devices?showAvailablePrograms=true')
    }
  }
  const routeToStep = (step: ProgramRequirementStep) => {
    navigate.push(step.path)
    closeBottomSheet()
  }
  const routeToNextUncompletedStep = () => {
    const nextStep = programRequirements?.steps.filter(
      (step) => !step.completed,
    )?.[0]
    if (!nextStep) {
      return
    }
    navigate.push(nextStep.path)
    closeBottomSheet()
  }
  // only use this when dealing with steps that the user can go back and change options
  // otherwise rely on requirements logic to manually route
  const routeToNextStep = () => {
    if (!programRequirements) {
      return
    }
    const currentStepIndex = programRequirements.steps.findIndex((s) =>
      location.pathname.includes(s.path),
    )
    const nextStep = programRequirements.steps[currentStepIndex + 1]
    if (!nextStep) {
      return
    }
    navigate.push(nextStep.path)
    closeBottomSheet()
  }
  const routeToPreviousStep = () => {
    if (!programRequirements) {
      return
    }
    const currentStepIndex = programRequirements.steps.findIndex((s) =>
      location.pathname.includes(s.path),
    )
    if (currentStepIndex < 1) {
      return
    }
    const previousStep = programRequirements.steps[currentStepIndex - 1]
    navigate.push(previousStep.path)
  }
  return {
    saveForLater,
    completeEnrollment,
    programRequirements,
    routeToStep,
    routeToNextStep,
    routeToPreviousStep,
    routeToNextUncompletedStep,
  }
}

function addClientSteps(
  serverSteps: ProgramRequirementProps['steps'],
  programId: number,
  program?: InProgressUtilityProgramEnrollment,
) {
  if (serverSteps.length === 0) {
    return []
  }
  serverSteps = addDeviceStep(serverSteps, programId, program)
  serverSteps = addReviewTOSStep(
    serverSteps,
    programId,
    program?.tosAccepted ?? false,
  )
  serverSteps = addEnrollmentStep(serverSteps, programId)
  return serverSteps
}

function addDeviceStep(
  serverSteps: ProgramRequirementProps['steps'],
  programId: number,
  program?: InProgressUtilityProgramEnrollment,
) {
  const deviceRequirement = serverSteps.find(
    (step) => ProfileEligibilityRequirements.Device === step.key,
  )
  serverSteps = serverSteps.filter(
    (s) => s.key !== ProfileEligibilityRequirements.Device,
  )
  if (!deviceRequirement) {
    return serverSteps
  }
  const stepConfig = requirementNameConfig.get(
    ProfileEligibilityRequirements.Device,
  )
  if (!stepConfig) {
    return serverSteps
  }
  const addDeviceStep = {
    name: stepConfig.name,
    path: `/multi-program-enrollment/${programId}/${stepConfig.path}`,
    completed: program ? isDeviceSelectionCompleted(program) : false,
    isNew: false,
    key: ClientRequirements.SelectDevice,
    clientStep: true,
  } satisfies ProgramRequirementStep
  return serverSteps.toSpliced(serverSteps.length, 0, addDeviceStep)
}

function addEnrollmentStep(
  serverSteps: ProgramRequirementProps['steps'],
  programId: number,
) {
  const stepConfig = requirementNameConfig.get(
    ClientRequirements.ProgramEnrollment,
  )
  if (!stepConfig) {
    return serverSteps
  }
  const programEnrollmentStep = {
    name: stepConfig.name,
    path: `/multi-program-enrollment/${programId}/${stepConfig.path}`,
    completed: false,
    isNew: false,
    key: ClientRequirements.ProgramEnrollment,
    clientStep: true,
  } satisfies ProgramRequirementStep
  return serverSteps.toSpliced(serverSteps.length, 0, programEnrollmentStep)
}

function addReviewTOSStep(
  serverSteps: ProgramRequirementProps['steps'],
  programId: number,
  tosAccepted: boolean,
) {
  const stepConfig = requirementNameConfig.get(ClientRequirements.ReviewTOS)
  if (!stepConfig) {
    return serverSteps
  }
  const reviewTOSStep = {
    name: stepConfig.name,
    path: `/multi-program-enrollment/${programId}/${stepConfig.path}`,
    completed: tosAccepted,
    isNew: false,
    key: ClientRequirements.ReviewTOS,
    clientStep: true,
  } satisfies ProgramRequirementStep
  return serverSteps.toSpliced(serverSteps.length, 0, reviewTOSStep)
}

function isDeviceSelectionCompleted(
  program: InProgressUtilityProgramEnrollment,
) {
  return (
    program.selectedDevices.vehicleIds.length > 0 ||
    program.selectedDevices.thermostatIds.length > 0 ||
    program.selectedDevices.chargerIds.length > 0
  )
}

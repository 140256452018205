import { Button } from '@/components'
import Text from '@/components/Text'
import 'react-spring-bottom-sheet/dist/style.css'
import { ProgressBar } from '@/app/components/ProgressBar/ProgressBar'
import { CheckIcon, ChevronRightIcon, ChevronUpIcon } from 'lucide-react'
import {
  BottomSheetConfig,
  useBottomSheet,
} from '@/context/bottom-sheet-context'
import {
  ProgramRequirementSheetProps,
  viewModel as useViewModel,
} from './useViewModel'
import { useMemo } from 'react'
import { sanitizeProgramName } from '@/types/utilityProgram/utils'

export function programRequirementsSheetConfig(
  props: ProgramRequirementSheetProps,
) {
  return {
    children: <ProgramRequirementsSteps {...props} />,
    open: true,
    header: <ProgramRequirementsHeader {...props} />,
    footer: <ProgramRequirementsFooter {...props} />,
    blocking: false,
    snapPoints: ({ minHeight, headerHeight }) => [minHeight, headerHeight],
    preventDismiss: true,
  } satisfies BottomSheetConfig
}

export function ProgramRequirementsHeader({
  utilityProgram,
}: ProgramRequirementSheetProps) {
  const { programRequirements } = useViewModel({ utilityProgram })
  const { sheetRef, sheetHeight } = useBottomSheet()
  const isMinimizedToHeader = useMemo(() => {
    if (!sheetHeight) {
      return true
    }
    return Math.floor(sheetHeight) > 127
  }, [sheetHeight])
  if (!programRequirements) {
    return null
  }
  const seeMore = () => {
    sheetRef.current?.snapTo((snaps) => snaps.minHeight)
  }
  const seeLess = () => {
    sheetRef.current?.snapTo((snaps) => snaps.headerHeight)
  }

  return (
    <div className={'flex flex-col gap-2 items-start'}>
      <div className={'flex flex-row w-full justify-between items-center'}>
        <Text variant={'h3'}>Requirements</Text>
        <div
          className={'flex flex-row items-center gap-1'}
          onClick={() => (!isMinimizedToHeader ? seeMore() : seeLess())}
        >
          <Text variant={'link'} className={'w-fit'}>
            {!isMinimizedToHeader ? 'See more' : 'See less'}{' '}
          </Text>
          <ChevronUpIcon
            size={20}
            className={`${
              isMinimizedToHeader ? 'rotate-180' : ''
            } transition-all text-base-blue-500`}
          />
        </div>
      </div>
      <Text variant={'body2'}>
        To finish enrolling in{' '}
        {sanitizeProgramName(programRequirements.planName)} program you must
        complete the following steps.
      </Text>
      <div className={'w-full'}>
        <ProgressBar
          step={programRequirements.step}
          numberOfSteps={programRequirements.numberOfSteps}
        />
      </div>
    </div>
  )
}

export function ProgramRequirementsFooter({
  utilityProgram,
}: ProgramRequirementSheetProps) {
  const { routeToNextUncompletedStep, saveForLater } = useViewModel({
    utilityProgram,
  })
  return (
    <div className={'flex gap-4 sm:flex-row flex-col'}>
      <Button id={'complete-all'} onClick={routeToNextUncompletedStep}>
        Complete All
      </Button>
      <Button
        id={'save-for-later'}
        variant={'primary-subtle'}
        onClick={saveForLater}
      >
        Save for Later
      </Button>
    </div>
  )
}

export function ProgramRequirementsSteps({
  utilityProgram,
}: ProgramRequirementSheetProps) {
  const { programRequirements, routeToStep } = useViewModel({
    utilityProgram,
  })
  if (!programRequirements) {
    return null
  }
  return (
    <div className={'flex flex-col m-1'}>
      {programRequirements.steps.map((step, index) => (
        <div
          key={step.name}
          className={`flex flex-row items-center gap-4 p-4 rounded-lg hover:bg-base-grey-100 cursor-pointer`}
          onClick={() => routeToStep(step)}
        >
          {step.completed ? (
            <div
              className={
                'bg-base-green-100 text-base-green-500 h-8 w-8 rounded-lg flex-none flex items-center justify-center'
              }
            >
              <CheckIcon size={18} />
            </div>
          ) : (
            <Text
              className={
                'bg-base-blue-100 text-base-blue-500 h-8 w-8 rounded-lg flex-none font-bold flex items-center justify-center'
              }
            >
              {index + 1}
            </Text>
          )}
          <Text>{step.name}</Text>
          <ChevronRightIcon
            className={'text-base-grey-400 ml-auto flex-none'}
            size={16}
          />
        </div>
      ))}
    </div>
  )
}
